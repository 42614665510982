import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../../../stores/CommonStore";
import { MiscellanyCategoryService } from "../../../../service/miscellany/MiscellanyService";
import MiscellanyCategory from "../../../../entities/productOrder/miscellany/MiscellanyCategory";

class MiscellanySalePriceStore {

    public miscellanyCategories: MiscellanyCategory[];
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.miscellanyCategories = [];
        this.commonStore = commonStore;
        makeObservable(this, {
            miscellanyCategories: observable,
            isEmpty: computed
        });
    }

    get isEmpty(): boolean {
        return this.miscellanyCategories.length === 0;
    }

    @action async getMiscellanyCategory() {
        const response = await MiscellanyCategoryService.getAll()
        if(response.success && response.data) {
            runInAction(() => {
                this.miscellanyCategories = response.data || []
            })
        }
    }

}

export default MiscellanySalePriceStore;