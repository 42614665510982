import {action, makeAutoObservable} from "mobx";
import SalePrice from "../../../entities/SalePrice";
import {SalePriceService} from "../../../service/SalePriceService";
import CommonStore from "../../../stores/CommonStore";


class CloneSalePriceStore {

    public salePrice: SalePrice;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, salePrice: SalePrice) {
        this.salePrice = salePrice;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async clone() {
        const response = await SalePriceService.clone(this.salePrice.id);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default CloneSalePriceStore;