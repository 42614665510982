import React from 'react';
import MiscellanySalePriceRowProperties from './MiscellanySalePriceRowProperties';
import ViewSmall from '../../../../../ui_components/MediaView/ViewSmall';
import MiscellanySalePriceRowMedium from './MiscellanySalePriceRowMedium';
import ViewMedium from '../../../../../ui_components/MediaView/ViewMedium';
import ViewLarge from '../../../../../ui_components/MediaView/ViewLarge';


const MiscellanySalePriceRow: React.FC<MiscellanySalePriceRowProperties> = ({
                                                    miscellanyCategory: article,
                                                    salePrice,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <MiscellanySalePriceRowMedium onUpdate={onUpdate} miscellanyCategory={article} salePrice={salePrice}/>
            </ViewSmall>
            <ViewMedium>
                <MiscellanySalePriceRowMedium onUpdate={onUpdate} miscellanyCategory={article} salePrice={salePrice}/>
            </ViewMedium>
            <ViewLarge>
                <MiscellanySalePriceRowMedium onUpdate={onUpdate} miscellanyCategory={article} salePrice={salePrice}/>
            </ViewLarge>
        </>
    )
}

export default MiscellanySalePriceRow;
