import React from 'react';
import SalePriceOptionsButtonProperties from "./SalePriceOptionsButtonProperties";
import {Observer} from "mobx-react-lite";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './SalePriceOptionsButton.style';
import NewSalePriceButton from '../NewSalePriceButton/NewSalePriceButton';
import RemoveSalePriceButton from '../RemoveSalePriceButton/RemoveSalePriceButton';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';


const SalePriceOptionsButton: React.FC<SalePriceOptionsButtonProperties> = ({
                                                                                onUpdate = () => {
                                                                                },
                                                                                className = '',
                                                                                salePrice
                                                                              }) => {

    const classes: any = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onUpdateClick = () => {
        onUpdate();
        setAnchorEl(null);
    };

    return <Observer>
        {() => (
            <>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={classes.icon_button}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    <NewSalePriceButton
                        onSave={onUpdateClick}
                        salePriceId={salePrice?.id}
                    />
                    <RemoveSalePriceButton 
                        salePrice={salePrice}
                        onSave={onUpdateClick}
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.SMALL}
                    />
                </Menu>
            </>
        )}
    </Observer>
}

export default SalePriceOptionsButton;
