import React from 'react';
import ArticleSalePriceRowProperties from './ArticleSalePriceRowProperties';
import ViewSmall from '../../../../../ui_components/MediaView/ViewSmall';
import ArticleSalePriceRowMedium from './ArticleSalePriceRowMedium';
import ViewMedium from '../../../../../ui_components/MediaView/ViewMedium';
import ViewLarge from '../../../../../ui_components/MediaView/ViewLarge';


const ArticleSalePriceRow: React.FC<ArticleSalePriceRowProperties> = ({
                                                    article,
                                                    salePrice,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <ArticleSalePriceRowMedium onUpdate={onUpdate} article={article} salePrice={salePrice}/>
            </ViewSmall>
            <ViewMedium>
                <ArticleSalePriceRowMedium onUpdate={onUpdate} article={article} salePrice={salePrice}/>
            </ViewMedium>
            <ViewLarge>
                <ArticleSalePriceRowMedium onUpdate={onUpdate} article={article} salePrice={salePrice}/>
            </ViewLarge>
        </>
    )
}

export default ArticleSalePriceRow;
