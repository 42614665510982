import React from 'react';
import {useStyles} from "../MoneySalePrices.style";
import clsx from "clsx";
import MoneySalePriceRowProperties from './MoneySalePriceRowProperties';
import { useI18nContext } from '../../../../../contexts/I18nContext';
import ShowInterval from '../../../../../shared_components/Info/ShowInterval/ShowInterval';
import ExpandoMenu from '../../../../../ui_components/ExpandoMenu/ExpandoMenu';
import Currency from '../../../../../entities/Currency';
import UpdateMoneySalePriceButton from '../../../../../shared_components/Buttons/UpdateMoneySalePriceButton/UpdateMoneySalePriceButton';
import ButtonSize from '../../../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../../../ui_components/Button/ButtonType';

const MoneySalePriceRowMedium: React.FC<MoneySalePriceRowProperties> = ({
                                                            moneyServiceFee,
                                                            salePrice,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    const currencies = [Currency.DOLLAR, Currency.MLC, Currency.MN];

    const moneySalePrice = salePrice?.moneySalePrices.find(t => t.serviceFeeId === moneyServiceFee.id);

    return (
        <div
            className={clsx(classes.money_sale_prices_info_container)}>
            <ShowInterval from={moneyServiceFee.fromValue} to={moneyServiceFee.toValue}/>
            <div>
                <div className={classes.currencies_values_container}>
                    {currencies.map((currency: Currency, i) => {
                        const salePriceCurrency = moneySalePrice?.salePriceByCurrency.find(b => b.currency === currency);
                        return (salePriceCurrency ? <div>
                            <div>{i18n.translate(currency)}</div>
                            <div>{!salePriceCurrency.isPercentage && <span>$</span>} {salePriceCurrency.salePrice} {salePriceCurrency.isPercentage && <span>%</span>}</div>
                        </div> : <div>
                            <div>{i18n.translate(currency)}</div>
                            <span>$</span> 0</div>
                        )
                    })}
                </div>
            </div>

            <ExpandoMenu>
               <>
                    <UpdateMoneySalePriceButton
                       size={ButtonSize.SMALL}
                       onSave={onUpdate}
                       showText
                       salePriceId={salePrice?.id ?? ''}
                       moneyServiceFeeId={moneyServiceFee.id}
                       moneySalePrice={moneySalePrice}
                       fromVValue={moneyServiceFee.fromValue}
                       toValue={moneyServiceFee.toValue}
                       type={ButtonType.SECONDARY}
                   />
               </>
            </ExpandoMenu>
        </div>
    )
}

export default MoneySalePriceRowMedium;
