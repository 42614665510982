import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ServiceName from '../../../../entities/ServiceName';
import ShowService from '../../../../shared_components/Info/ShowService/ShowService';
import ShowText from '../../../../shared_components/Info/ShowText/ShowText';
import SalePriceTableReferenceItemRowProperties from './SalePriceTableReferenceItemRowProperties';
import ReferenceItem from '../../../../model/packageDelivery/referenceItem/ReferenceItem';
import ShowEditReferenceItemSalePrice from '../../../../shared_components/InfoEditables/ShowEditReferenceItemSalePrice/ShowEditReferenceItemSalePrice';


const SalePriceTableReferenceItemRow: React.FC<SalePriceTableReferenceItemRowProperties> = ({
        referenceItems, 
        salePrices, 
        onUpdate
    }) => {

    const onUpdateValue = () => {
        onUpdate();
    }

    return (
        <>
        <TableRow hover role="checkbox" tabIndex={-1} key={'row.id'}>
            <TableCell key={`column.id`} align={'right'}>
                <ShowService service={ServiceName.PACKAGE_DELIVERY}/>
            </TableCell>
        </TableRow>
        {referenceItems.map((referenceItem: ReferenceItem) => {
            return (
                <TableRow>
                    <TableCell key={referenceItem.id} align={'left'}>
                        <ShowText text={referenceItem.name}/>
                    </TableCell>
                    {salePrices.map((price) => {
                        const referenceItemSalePrice = price.packageReferenceItemSalePrices ? price.packageReferenceItemSalePrices.find(t => t.referenceItemId === referenceItem.id) : null;
                        return (
                        <TableCell key={price.id} align={'left'}>
                            <ShowEditReferenceItemSalePrice
                                value={referenceItemSalePrice ? referenceItemSalePrice.salePrice : 0}
                                salePriceId={price.id}
                                referenceItemId={referenceItem.id}
                                onSave={onUpdateValue}/>
                        </TableCell>
                        );
                    })}
                </TableRow>
            );
        })}
        </>
    )
}

export default SalePriceTableReferenceItemRow;
