import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    miscellany_sale_prices_container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        width: '100%',
        height: 'calc(100vh - 332px)',
        overflow: 'auto'
    },
    miscellany_sale_prices_table: {
        display: 'flex',
        flexDirection: 'column',
    },
    elements_container: {},
    miscellany_sale_prices_info_container: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: 'auto 200px 80px',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
        "&:hover": {
            backgroundColor: `${theme.palette.grey[200]}`,
            cursor: 'pointer',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
        }
    },
    miscellany_sale_prices_info_container_small: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        placeItems: 'stretch',
        rowGap: 16,
        columnGap: 16,
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
    },
    inactive: {
        borderLeft: `8px solid ${theme.palette.grey["300"]}`,
        backgroundColor: `${theme.palette.grey["300"]}8c`,
        opacity: '0.8'
    },
    menu_container: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        "& > *": {
            marginLeft: '4px'
        }
    },
    menu_container_small: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        "& > *": {
            marginLeft: '4px'
        }
    },
    filter_container: {
        display: "grid",
        marginTop: '16px',
        gridTemplateColumns: "repeat( auto-fit, minmax(280px, 1fr) )",
        rowGap: 16,
        "& button": {
            justifySelf: "end"
        }
    },
    tools_container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    search_btn: {
        justifySelf: 'flex-end'
    },
    currencies_values_container: {
        display: 'flex',
        gap: '50px'
    },
    article_name_container: {
        display: 'flex',
        alignItems: 'center'
    },
    padding_left_10: {
        paddingLeft: '10px'
    }
}));

export {useStyles}