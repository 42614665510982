import React from 'react';
import {useStyles} from "./MoneySalePrices.style";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useCommonContext } from '../../../../contexts/CommonContext';
import { useI18nContext } from '../../../../contexts/I18nContext';
import MoneySalePriceStore from './MoneySalePriceStore';
import { Observer } from 'mobx-react-lite';
import MoneySalePriceRow from './MoneySalePricesRow/MoneySalePriceRow';
import MessageCard from '../../../../ui_components/MessageCard/MessageCard';
import MoneySalePricesProperties from './MoneySalePricesProperties';


const MoneySalePrices: React.FC<MoneySalePricesProperties> = ({
    salePrice,
    onUpdate = () => {
    },
}) => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const store = new MoneySalePriceStore(commonStore);
    const classes = useStyles();

    store.getMoneySalePrices();

    return (
        <Observer>
            {() => (
                <div className={classes.money_sale_prices_container}>
                    {!store.isEmpty &&
                    <div className={classes.money_sale_prices_table}>
                        <div className={classes.elements_container}>
                            {store.moneyServiceFees.map((moneyServiceFee, i) => (
                                <MoneySalePriceRow
                                    onUpdate={onUpdate}
                                    moneyServiceFee={moneyServiceFee}
                                    salePrice={salePrice}
                                    key={i}
                                />
                            ))}
                        </div>
                    </div>}
                    {store.isEmpty &&
                    <MessageCard icon={CurrencyExchangeIcon} title={i18n.translate("NO_MONEY_SERVICE_FEE_TITLE")}
                                 subtitle={i18n.translate("NO_MONEY_SERVICE_FEE_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default MoneySalePrices;
