import {action, computed, makeObservable, observable} from "mobx";
import CommonStore from "../../../../stores/CommonStore";
import { ProductService } from "../../../../service/topup/ProductService";
import Product from "../../../../entities/productOrder/topup/Product";

class TopupSalePriceStore {

    public topupProducts: Product[];
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.topupProducts = [];
        this.commonStore = commonStore;
        makeObservable(this, {
            topupProducts: observable,
            isEmpty: computed
        });
    }

    get isEmpty(): boolean {
        return this.topupProducts.length === 0;
    }

    @action
    public async getTopupProducts() {
        const response = await ProductService.get();
        if (response.success && response.data) {
            this.topupProducts = response.data
        }
    }

}

export default TopupSalePriceStore;