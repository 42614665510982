import React from 'react';
import NewSalePriceButtonProperties from "./NewSalePriceButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import NewSalePriceStore from "./NewSalePriceStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import SellerNameInput from "../../Inputs/SellerNameInput/SellerNameInput";
import {useStyles} from "./NewSalePriceButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Textarea from '../../../ui_components/Textarea/Textarea';
import HomeIcon from '@mui/icons-material/Home';
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';


const NewSalePriceButton: React.FC<NewSalePriceButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  salePriceId,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewSalePriceStore(commonContext, salePriceId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onNameChange = (newName: string) => store.salePrice.name = newName;
    const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        store.salePrice.description = event.target.value;
    }

    const onSalePriceSave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }

    const text = i18n.translate((store.isEditing ? "EDIT_SALE_PRICE_LIST" : "NEW_SALE_PRICE_LIST"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <SellerNameInput error={i18n.translate(store.nameError)} fullWidth className={classes.input}
                                         value={store.salePrice?.name}
                                         onChange={onNameChange}/>
                        <Textarea
                            label={i18n.translate("DESCRIPTION")}
                            value={store.salePrice?.description}
                            lines={3}
                            disabled={disabled}
                            onChange={onDescriptionChange}
                            startIcon={HomeIcon}
                            className={classes.input}
                            fullWidth={true}
                        />
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onSalePriceSave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewSalePriceButton;
