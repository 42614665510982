import {action, makeAutoObservable, observable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { SalePriceService } from "../../../service/SalePriceService";
import Currency from "../../../entities/Currency";

class ShowEditMoneySalePriceStore {

    public salePriceId: string;
    public serviceFeeId: string;
    public value: number;
    public moneyServiceFeeType: string;
    public isPercentage: boolean;
    public currency: Currency;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(
        commonStore: CommonStore,
        salePriceId: string,
        serviceFeeId: string,
        value: number,
        isPercentage: boolean,
        currency: Currency
    ) {
        this.loading = false;
        this.commonStore = commonStore;
        this.salePriceId = salePriceId;
        this.serviceFeeId = serviceFeeId;
        this.value = value;
        this.isPercentage = isPercentage;
        this.currency = currency;
        this.moneyServiceFeeType = isPercentage ? 'PERCENT' : 'UNITARY'
        this.isModalOpen = false;
        makeAutoObservable(this, {
            isModalOpen: observable
        });
    }

    @action
    public openModal(): void {
        this.isModalOpen = true;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async updateMoneySalePrice() {
        const response = 
            await SalePriceService.updateMoneySalePriceValue(
                this.salePriceId,
                this.serviceFeeId,
                this.value,
                this.moneyServiceFeeType === 'PERCENT',
                this.currency);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default ShowEditMoneySalePriceStore;