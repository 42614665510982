import React from 'react';
import {useStyles} from "../ArticleSalePrices.style";
import clsx from "clsx";
import ArticleSalePriceRowProperties from './ArticleSalePriceRowProperties';
import ExpandoMenu from '../../../../../ui_components/ExpandoMenu/ExpandoMenu';
import ShowText from '../../../../../shared_components/Info/ShowText/ShowText';
import ShowImage from '../../../../../shared_components/Info/ShowImage/ShowImage';
import UpdateArticleSalePriceButton from '../../../../../shared_components/Buttons/UpdateArticleSalePriceButton/UpdateArticleSalePriceButton';
import ButtonSize from '../../../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../../../ui_components/Button/ButtonType';

const ArticleSalePriceRowMedium: React.FC<ArticleSalePriceRowProperties> = ({
                                                            article,
                                                            salePrice,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {

    const classes = useStyles();

    const articleSalePrice = salePrice?.articleSalePrices.find(t => t.articleId === article.id);

    return (
        <div
            className={clsx(classes.article_sale_prices_info_container)}>
            <ShowImage imageName={article.defaultImage} width={60} height={60}/>
            <ShowText text={article.name} className={classes.article_name_container}/>
            <ShowText text={articleSalePrice?.salePrice ? '$' + articleSalePrice?.salePrice.toString() : '0'}
                title={'Valor de venta'} className={classes.padding_left_10}/>
            <ExpandoMenu>
               <>
                    <UpdateArticleSalePriceButton
                       size={ButtonSize.SMALL}
                       onSave={onUpdate}
                       showText
                       salePriceId={salePrice?.id ?? ''}
                       articleId={article.id}
                       value={articleSalePrice?.salePrice ?? 0}
                       type={ButtonType.SECONDARY}
                   />
               </>
            </ExpandoMenu>
        </div>
    )
}

export default ArticleSalePriceRowMedium;
