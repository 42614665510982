import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../../../stores/CommonStore";
import { ReferenceItemService } from "../../../../service/packageDelivery/ReferenceItem/ReferenceItemService";
import ReferenceItem from "../../../../model/packageDelivery/referenceItem/ReferenceItem";

class PackageSalePriceStore {

    public referenceItems: ReferenceItem[];
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.referenceItems = [];
        this.commonStore = commonStore;
        makeObservable(this, {
            referenceItems: observable,
            isEmpty: computed
        });
    }

    get isEmpty(): boolean {
        return this.referenceItems.length === 0;
    }

    @action async getReferenceItems() {
        const response = await ReferenceItemService.getAll()
        if(response.success && response.data) {
            runInAction(() => {
                this.referenceItems = response.data || []
            })
        }
    }

}

export default PackageSalePriceStore;