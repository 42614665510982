import React from 'react';
import MoneySalePriceRowProperties from './MoneySalePriceRowProperties';
import ViewSmall from '../../../../../ui_components/MediaView/ViewSmall';
import MoneySalePriceRowMedium from './MoneySalePriceRowMedium';
import ViewMedium from '../../../../../ui_components/MediaView/ViewMedium';
import ViewLarge from '../../../../../ui_components/MediaView/ViewLarge';


const MoneySalePriceRow: React.FC<MoneySalePriceRowProperties> = ({
                                                    moneyServiceFee,
                                                    salePrice,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <MoneySalePriceRowMedium onUpdate={onUpdate} moneyServiceFee={moneyServiceFee} salePrice={salePrice}/>
            </ViewSmall>
            <ViewMedium>
                <MoneySalePriceRowMedium onUpdate={onUpdate} moneyServiceFee={moneyServiceFee} salePrice={salePrice}/>
            </ViewMedium>
            <ViewLarge>
                <MoneySalePriceRowMedium onUpdate={onUpdate} moneyServiceFee={moneyServiceFee} salePrice={salePrice}/>
            </ViewLarge>
        </>
    )
}

export default MoneySalePriceRow;
