import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { SalePriceService } from "../../../service/SalePriceService";

class UpdateTopupSalePriceStore {

    public salePriceId: string;
    public topupProductId: string;
    public value: number;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    public changeError?: string;

    constructor(commonStore: CommonStore, salePriceId: string, topupProductId: string, value: number) {
        this.salePriceId = salePriceId;
        this.topupProductId = topupProductId;
        this.value = value;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save() {
        const response = await SalePriceService.updateTopupSalePrice(this.salePriceId, this.topupProductId, this.value);
        this.commonStore.processErrors(response);
        return response.success;
    }

}

export default UpdateTopupSalePriceStore;