import React from 'react';
import PackageSalePriceRowProperties from './PackageSalePriceRowProperties';
import ViewSmall from '../../../../../ui_components/MediaView/ViewSmall';
import PackageSalePriceRowMedium from './PackageSalePriceRowMedium';
import ViewMedium from '../../../../../ui_components/MediaView/ViewMedium';
import ViewLarge from '../../../../../ui_components/MediaView/ViewLarge';


const PackageSalePriceRow: React.FC<PackageSalePriceRowProperties> = ({
                                                    referenceItem,
                                                    salePrice,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <PackageSalePriceRowMedium onUpdate={onUpdate} referenceItem={referenceItem} salePrice={salePrice}/>
            </ViewSmall>
            <ViewMedium>
                <PackageSalePriceRowMedium onUpdate={onUpdate} referenceItem={referenceItem} salePrice={salePrice}/>
            </ViewMedium>
            <ViewLarge>
                <PackageSalePriceRowMedium onUpdate={onUpdate} referenceItem={referenceItem} salePrice={salePrice}/>
            </ViewLarge>
        </>
    )
}

export default PackageSalePriceRow;
