import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import SalePrice from "../../../entities/SalePrice";
import {SalePriceService} from "../../../service/SalePriceService";
import Pagination from "../../../entities/Pagination";


class SalePriceSelectStore {

    public loading: boolean;
    public commonStore: CommonStore;
    public filter?: string;
    public elements: SalePrice[];
    public pagination: Pagination;
    public selectedSalePrice?: SalePrice;
    public isSearchDialogOpen: boolean;


    constructor(commonStore: CommonStore, selectedSalePrice?: SalePrice) {
        this.loading = false;
        this.commonStore = commonStore;
        this.pagination = new Pagination(1, 15, 0, 0);
        this.elements = [];
        this.selectedSalePrice = selectedSalePrice;
        this.isSearchDialogOpen = false;
        makeAutoObservable(this);
    }

    @action
    public async search(): Promise<void> {
        this.loading = true;
        const response = await SalePriceService.get(1, 20, this.filter);
        if (response.success) {
            runInAction(() => {
                this.elements = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public toggleSearchDialog(value?: boolean) {
        value = (value === undefined) ? !this.isSearchDialogOpen : value;
        this.isSearchDialogOpen = value;
    }

    get isEmpty(): boolean {
        return this.elements.length === 0;
    }

    @action
    public async setFilter(filter: string) {
        this.filter = filter;
        await this.search();
    }

    @action
    public setSelectedSalePrice(salePrice?: SalePrice) {
        this.selectedSalePrice = salePrice;
        this.isSearchDialogOpen = false;
    }

}

export default SalePriceSelectStore;