import React from 'react';
import {useStyles} from "../PackageSalePrices.style";
import clsx from "clsx";
import PackageSalePriceRowProperties from './PackageSalePriceRowProperties';
import ExpandoMenu from '../../../../../ui_components/ExpandoMenu/ExpandoMenu';
import ShowText from '../../../../../shared_components/Info/ShowText/ShowText';
import ButtonSize from '../../../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../../../ui_components/Button/ButtonType';
import UpdatePackageReferenceItemSalePriceButton from '../../../../../shared_components/Buttons/UpdatePackageReferenceItemSalePriceButton/UpdatePackageReferenceItemSalePriceButtonButton';

const PackageSalePriceRowMedium: React.FC<PackageSalePriceRowProperties> = ({
                                                            referenceItem,
                                                            salePrice,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {

    const classes = useStyles();

    const packageSalePrice = salePrice?.packageReferenceItemSalePrices.find(t => t.referenceItemId === referenceItem.id);

    return (
        <div
            className={clsx(classes.package_sale_prices_info_container)}>
            <ShowText text={referenceItem.name} className={classes.package_name_container}/>
            <ShowText text={packageSalePrice?.salePrice ? '$' + packageSalePrice?.salePrice.toString() : '0'}
                title={'Valor de venta'} className={classes.padding_left_10}/>
            <ExpandoMenu>
               <>
                    <UpdatePackageReferenceItemSalePriceButton
                       size={ButtonSize.SMALL}
                       onSave={onUpdate}
                       showText
                       salePriceId={salePrice?.id ?? ''}
                       referenceItemId={referenceItem.id}
                       value={packageSalePrice?.salePrice ?? 0}
                       type={ButtonType.SECONDARY}
                   />
               </>
            </ExpandoMenu>
        </div>
    )
}

export default PackageSalePriceRowMedium;
