import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ServiceName from '../../../../entities/ServiceName';
import ShowService from '../../../../shared_components/Info/ShowService/ShowService';
import ShowText from '../../../../shared_components/Info/ShowText/ShowText';
import SalePriceTableArticleRowProperties from './SalePriceTableArticleRowProperties';
import ShowEditArticleSalePrice from '../../../../shared_components/InfoEditables/ShowEditArticleSalePrice/ShowEditArticleSalePrice';


const SalePriceTableArticleRow: React.FC<SalePriceTableArticleRowProperties> = ({
    articles, salePrices, onUpdate
                                                  }) => {

    const onUpdateValue = () => {
        onUpdate();
    }

    return (
        <>
        <TableRow hover role="checkbox" tabIndex={-1} key={'row.id'}>
            <TableCell key={`column.id`} align={'right'}>
                <ShowService service={ServiceName.ARTICLE_DELIVERY}/>
            </TableCell>
        </TableRow>
        {articles.map((article: any) => {
            return (
                <TableRow>
                    <TableCell key={article.id} align={'left'}>
                        <ShowText text={article.name}/>
                    </TableCell>
                    {salePrices.map((price) => {
                        const articleSalePrice = price.articleSalePrices ? price.articleSalePrices.find(t => t.articleId === article.id) : null;
                        return (
                        <TableCell key={price.id} align={'left'}>
                            <ShowEditArticleSalePrice
                                value={articleSalePrice ? articleSalePrice.salePrice : 0}
                                salePriceId={price.id}
                                articleId={article.id}
                                onSave={onUpdateValue}/>
                        </TableCell>
                        );
                    })}
                </TableRow>
            );
        })}
        </>
    )
}

export default SalePriceTableArticleRow;
