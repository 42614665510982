import React from 'react';
import {useStyles} from "../TopupSalePrices.style";
import clsx from "clsx";
import TopupSalePriceRowProperties from './TopupSalePriceRowProperties';
import ExpandoMenu from '../../../../../ui_components/ExpandoMenu/ExpandoMenu';
import ShowProduct from '../../../../../shared_components/Info/ShowProduct/ShowProduct';
import ShowText from '../../../../../shared_components/Info/ShowText/ShowText';
import UpdateTopupSalePriceButton from '../../../../../shared_components/Buttons/UpdateTopupSalePriceButton/UpdateTopupSalePriceButton';
import ButtonSize from '../../../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../../../ui_components/Button/ButtonType';

const TopupSalePriceRowMedium: React.FC<TopupSalePriceRowProperties> = ({
                                                            topupProduct,
                                                            salePrice,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {

    const classes = useStyles();

    const topupSalePrice = salePrice?.topupSalePrices.find(t => t.productId === topupProduct.id);

    return (
        <div
            className={clsx(classes.topup_sale_prices_info_container)}>
            <ShowProduct value={topupProduct}/>
            <ShowText text={topupSalePrice?.salePrice ? '$' + topupSalePrice?.salePrice.toString() : '0'} title={'Valor de venta'}/>
            <ExpandoMenu>
               <>
                    <UpdateTopupSalePriceButton
                       size={ButtonSize.SMALL}
                       onSave={onUpdate}
                       showText
                       salePriceId={salePrice?.id ?? ''}
                       topupProductId={topupProduct.id}
                       value={topupSalePrice?.salePrice ?? 0}
                       type={ButtonType.SECONDARY}
                   />
               </>
            </ExpandoMenu>
        </div>
    )
}

export default TopupSalePriceRowMedium;
