import {action, computed, makeObservable, observable, runInAction} from "mobx";
import MoneyDeliveryFee from "../../../../entities/productOrder/moneyDelivery/MoneyDeliveryFee";
import CommonStore from "../../../../stores/CommonStore";
import { MoneyServiceFeeService } from "../../../../service/moneyDelivery/MoneyServiceFeeService";

class MoneySalePriceStore {

    public moneyServiceFees: MoneyDeliveryFee[];
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.moneyServiceFees = [];
        this.commonStore = commonStore;
        makeObservable(this, {
            moneyServiceFees: observable,
            isEmpty: computed
        });
    }

    get isEmpty(): boolean {
        return this.moneyServiceFees.length === 0;
    }

    @action
    public async getMoneySalePrices() {
        const response = await MoneyServiceFeeService.getAll();
        if (response.success && response.data) {
            runInAction(() => {
                this.moneyServiceFees = response.data || [];
            })            
        }
    }

}

export default MoneySalePriceStore;