import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Currency from "../../../entities/Currency";
import MoneySalePrice from "../../../entities/productOrder/moneyDelivery/MoneySalePrice";
import { SalePriceService } from "../../../service/SalePriceService";
import MoneyDeliveryFeeCurrencyValue from "../../../entities/productOrder/moneyDelivery/MoneyDeliveryFeeCurrencyValue";
import MoneySalePriceValueRequest from "../../../model/salePrice/MoneySalePriceValueRequest";

class UpdateMoneySalePriceStore {

    public salePriceId: string;
    public moneyServiceFeeId: string;
    public moneySalePrice?: MoneySalePrice;
    public moneyDeliveryFeeCurrencyValues: MoneyDeliveryFeeCurrencyValue[];
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    public changeError?: string;

    constructor(commonStore: CommonStore, salePriceId: string, moneyServiceFeeId: string, moneySalePrice?: MoneySalePrice) {
        this.salePriceId = salePriceId;
        this.moneyServiceFeeId = moneyServiceFeeId;
        this.moneySalePrice = moneySalePrice;
        this.moneyDeliveryFeeCurrencyValues = moneySalePrice?.salePriceByCurrency.map(t => new MoneyDeliveryFeeCurrencyValue(t.currency, t.salePrice, t.isPercentage)) ||
            [
                new MoneyDeliveryFeeCurrencyValue(Currency.DOLLAR, 0, false),
                new MoneyDeliveryFeeCurrencyValue(Currency.MLC, 0, false),
                new MoneyDeliveryFeeCurrencyValue(Currency.MN, 0, false),
            ];
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const response = await SalePriceService.updateMoneySalePriceValues(
            this.salePriceId,
            this.moneyServiceFeeId,
            this.moneyDeliveryFeeCurrencyValues.map(t => new MoneySalePriceValueRequest(t.value, t.currency, t.isPercentage))
        );
        this.commonStore.processErrors(response);
        this.loading = false;
        return response.success;
    }

}

export default UpdateMoneySalePriceStore;