import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ServiceName from '../../../../entities/ServiceName';
import ShowService from '../../../../shared_components/Info/ShowService/ShowService';
import ShowText from '../../../../shared_components/Info/ShowText/ShowText';
import SalePriceTableTopupRowProperties from './SalePriceTableTopupRowProperties';
import ShowEditTopupSalePrice from '../../../../shared_components/InfoEditables/ShowEditTopupSalePrice/ShowEditTopupSalePrice';


const SalePriceTableTopupRow: React.FC<SalePriceTableTopupRowProperties> = ({
    products, salePrices, onUpdate
                                                  }) => {

    const onUpdateValue = () => {
        onUpdate();
    }

    return (
        <>
        <TableRow hover role="checkbox" tabIndex={-1} key={'row.id'}>
            <TableCell key={`column.id`} align={'right'}>
                <ShowService service={ServiceName.TOPUP}/>
            </TableCell>
        </TableRow>
        {products.map((product: any) => {
            return (
                <TableRow>
                    <TableCell key={product.id} align={'left'}>
                        <ShowText text={product.name}/>
                    </TableCell>
                    {salePrices.map((price) => {
                        const topupSalePrice = price.topupSalePrices ? price.topupSalePrices.find(t => t.productId === product.id) : null;
                        return (
                        <TableCell key={price.id} align={'left'}>
                            <ShowEditTopupSalePrice value={topupSalePrice ? topupSalePrice.salePrice : 0}
                                salePriceId={price.id}
                                productId={product.id}
                                onSave={onUpdateValue}/>
                        </TableCell>
                        );
                    })}
                </TableRow>
            );
        })}
        </>
    )
}

export default SalePriceTableTopupRow;
