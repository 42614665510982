import Pagination from "../../../entities/Pagination";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {SalePriceService} from "../../../service/SalePriceService";
import CommonStore from "../../../stores/CommonStore";
import SalePrice from "../../../entities/SalePrice";

class DetailedSalePricesStore {

    public selectedSalePriceId?: string;
    public selectedSalePrice?: SalePrice;

    public salePrices: SalePrice[];
    public pagination: Pagination;
    public filter?: string;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.pagination = new Pagination(1, 15, 0, 0);
        this.salePrices = [];
        this.commonStore = commonStore;
        makeObservable(this, {
            salePrices: observable,
            pagination: observable,
            selectedSalePriceId: observable,
            selectedSalePrice: observable,
            isEmpty: computed
        });
    }

    get haveNotSelected(): boolean {
        return this.selectedSalePrice === undefined;
    }

    get isEmpty(): boolean {
        return this.salePrices.length === 0;
    }

    public setFilter(filer: string) {
        this.filter = filer;
    }

    @action
    public async search() {
        await this.getElements(1);
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.commonStore.setLoading();
        const response = await SalePriceService.get(page, pageSize, this.filter);
        if (response.success) {
            runInAction(() => {
                this.salePrices = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

}

export default DetailedSalePricesStore;