import React from 'react';
import {useStyles} from "./SalePrices.style";
import {Observer} from "mobx-react-lite";
import Tabs from '../../ui_components/Tab/Tabs';
import TabOption from '../../ui_components/Tab/TabOption';
import ComparativeSalePrices from './ComparativeSalePrices/ComparativeSalePrices';
import DetailedSalePrices from './DetailedSalePrices/DetailedSalePrices';

const SalePrices: React.FC = () => {
    const classes = useStyles();

    const tabsContent = [
        new TabOption("Vista detallada", <DetailedSalePrices/>),
        new TabOption("Vista comparativa", <ComparativeSalePrices/>),
    ];

    return (
        <Observer>
            {() => (
                <div className={classes.clients_container}>
                    <Tabs content={tabsContent}/>                    
                </div>
            )}
        </Observer>
    )
}

export default SalePrices;
