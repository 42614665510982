import React from 'react';
import UpdateTopupSalePriceButtonProperties from "./UpdateTopupSalePriceButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./UpdateTopupSalePriceButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import TextInput from '../../../ui_components/TextInput/TextInput';
import TextInputType from '../../../ui_components/TextInput/TextInputType';
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import Access from "../../Access/AllowAccess/Access";
import {useUserContext} from "../../../contexts/UserContext";
import UpdateArticleSalePriceStore from './UpdateTopupSalePriceButtonStore';

const UpdateTopupSalePriceButton: React.FC<UpdateTopupSalePriceButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  salePriceId,
                                                                  topupProductId,
                                                                  value,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new UpdateArticleSalePriceStore(commonContext, salePriceId, topupProductId, value);

    const userStore = useUserContext();
    if (!userStore.hasAccess(Access.CREATE_SALE_PRICE.valueOf())) {
        return <></>;
    }

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newVal = event.target.value;
        store.value = +newVal;
    }

    const onSalePriceValuesSave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }

    const text = "Editar precio de venta";
    const btnIcon = <Icon name={IconName.EDIT_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text}
                    onClose={onCloseModal}
                    onPressEnter={onSalePriceValuesSave}
                    open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <TextInput
                            label={i18n.translate('NEW_VALUE')}
                            value={store.value}
                            onChange={onValueChange}
                            type={TextInputType.NUMBER}
                            className={classes.text_input}
                            fullWidth
                        />
                        <Button
                            loading={store.loading} className={classes.input} onClick={onSalePriceValuesSave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default UpdateTopupSalePriceButton;
