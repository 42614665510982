import Pagination from "../../../entities/Pagination";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {SalePriceService} from "../../../service/SalePriceService";
import CommonStore from "../../../stores/CommonStore";
import SalePrice from "../../../entities/SalePrice";
import Product from "../../../entities/productOrder/topup/Product";
import SimpleArticleResponse from "../../../model/articleDelivery/SimpleArticleResponse";
import MoneyDeliveryFee from "../../../entities/productOrder/moneyDelivery/MoneyDeliveryFee";
import { MoneyServiceFeeService } from "../../../service/moneyDelivery/MoneyServiceFeeService";
import { ProductService } from "../../../service/topup/ProductService";
import ReferenceItem from "../../../model/packageDelivery/referenceItem/ReferenceItem";
import { ReferenceItemService } from "../../../service/packageDelivery/ReferenceItem/ReferenceItemService";
import { ArticleService } from "../../../service/articleDelivery/ArticleService";
import MiscellanyCategory from "../../../entities/productOrder/miscellany/MiscellanyCategory";
import { MiscellanyCategoryService } from "../../../service/miscellany/MiscellanyService";

class ComparativeSalePricesStore {

    public salePrices: SalePrice[];
    public pagination: Pagination;
    public filter?: string;
    public commonStore: CommonStore;

    // Topup Products
    public topupProducts: Product[];

    // Articles
    public articles: SimpleArticleResponse[];

    // Money service fees
    public moneyServiceFees: MoneyDeliveryFee[];

    // Reference Items
    public referenceItems: ReferenceItem[];

    // Miscellany Category
    public miscellanyCategories: MiscellanyCategory[];

    constructor(commonStore: CommonStore) {
        this.pagination = new Pagination(1, 15, 0, 0);
        this.salePrices = [];
        this.commonStore = commonStore;
        this.topupProducts = [];
        this.articles = [];
        this.moneyServiceFees = [];
        this.referenceItems = []
        this.miscellanyCategories = []
        makeObservable(this, {
            salePrices: observable,
            topupProducts: observable,
            articles: observable,
            moneyServiceFees: observable,
            referenceItems: observable,
            miscellanyCategories: observable,
            pagination: observable,
            isEmpty: computed
        });
    }

    get isEmpty(): boolean {
        return this.salePrices.length === 0;
    }

    public setFilter(filer: string) {
        this.filter = filer;
    }

    @action
    public async search() {
        await this.getElements(1);
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.commonStore.setLoading();
        const response = await SalePriceService.get(page, pageSize, this.filter);
        if (response.success) {
            runInAction(() => {
                this.salePrices = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

    @action
    public async getTopupProducts() {
        const response = await ProductService.get();
        if (response.success && response.data) {
            this.topupProducts = response.data
        }
    }

    @action
    public async getArticles() {
        const response = await ArticleService.getAll();
        if (response.success && response.data) {
            runInAction(() => {
                this.articles = response.data || [];
            })            
        }
    }

    @action
    public async getMoneyServiceFees() {
        const response = await MoneyServiceFeeService.getAll();
        if (response.success && response.data) {
            runInAction(() => {
                this.moneyServiceFees = response.data || [];
            })            
        }
    }

    @action async getReferenceItems() {
        const response = await ReferenceItemService.getAll()
        if(response.success && response.data) {
            runInAction(() => {
                this.referenceItems = response.data || []
            })
        }
    }

    @action async getMiscellanyCategoryItems() {
        const response = await MiscellanyCategoryService.getAll()
        if(response.success && response.data) {
            runInAction(() => {
                this.miscellanyCategories = response.data || []
            })
        }
    }

}

export default ComparativeSalePricesStore;