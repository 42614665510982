import Currency from "../../Currency";

class MoneyDeliveryFeeCurrencyValue {
    public currency: Currency;
    public value: number;
    public isPercentage: boolean;

    constructor(
        currency: Currency,
        value: number,
        isPercentage: boolean,
    ) {
        this.currency = currency;
        this.value = value;
        this.isPercentage = isPercentage;
    }
}

export default MoneyDeliveryFeeCurrencyValue;