import React from 'react';
import {useStyles} from "../MiscellanySalePrices.style";
import clsx from "clsx";
import MiscellanySalePriceRowProperties from './MiscellanySalePriceRowProperties';
import ExpandoMenu from '../../../../../ui_components/ExpandoMenu/ExpandoMenu';
import ShowText from '../../../../../shared_components/Info/ShowText/ShowText';
import UpdateMiscellanySalePriceButton from '../../../../../shared_components/Buttons/UpdateMiscellanySalePriceButton/UpdateMiscellanySalePriceButton';
import ButtonSize from '../../../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../../../ui_components/Button/ButtonType';

const MiscellanySalePriceRowMedium: React.FC<MiscellanySalePriceRowProperties> = ({
                                                            miscellanyCategory,
                                                            salePrice,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {

    const classes = useStyles();

    const miscellanyCategorySalePrice = salePrice?.miscellanySalePrices.find(t => t.miscellanyCategoryId === miscellanyCategory.id);

    return (
        <div
            className={clsx(classes.miscellany_sale_prices_info_container)}>
            <ShowText text={miscellanyCategory.name} className={classes.article_name_container}/>
            <ShowText text={miscellanyCategorySalePrice?.salePrice ? '$' + miscellanyCategorySalePrice?.salePrice.toString() : '0'}
                title={'Valor de venta'} className={classes.padding_left_10}/>
            <ExpandoMenu>
               <>
                    <UpdateMiscellanySalePriceButton
                       size={ButtonSize.SMALL}
                       onSave={onUpdate}
                       showText
                       salePriceId={salePrice?.id ?? ''}
                       miscellanyCategoryId={miscellanyCategory.id}
                       value={miscellanyCategorySalePrice?.salePrice ?? 0}
                       type={ButtonType.SECONDARY}
                   />
               </>
            </ExpandoMenu>
        </div>
    )
}

export default MiscellanySalePriceRowMedium;
