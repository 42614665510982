import React, {useState} from 'react';
import {Tab, Tabs as MaterialTabs} from "@material-ui/core";
import TabsProperties from "./TabsProperties";
import {useStyles} from "./Tab.style";
import TabPanel from "./TabPanel";
import clsx from 'clsx';


const Tabs: React.FC<TabsProperties> = ({
                                            content,
                                            orientation = 'horizontal',
                                            className,
                                        }: TabsProperties) => {

    const classes = useStyles();

    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <div className={clsx(classes.tab_container,className)}>
                <MaterialTabs value={selectedTab} onChange={handleChange} orientation={orientation}>
                    {content.map((e, i) => (<Tab className={classes.tab} icon={e.icon} key={i} label={e.label}/>))}
                </MaterialTabs>
            </div>
            {content.map((e, i) => (
                <TabPanel value={selectedTab} index={i} key={i}>
                    {e.content}
                </TabPanel>))}
        </>
    )
}
export default Tabs;