import React, {createRef, useEffect} from 'react';
import SalePriceSelectProperties from "./SalePriceSelectProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useCommonContext} from "../../../contexts/CommonContext";
import {useStyles} from "./SalePriceSelect.style";
import clsx from "clsx";
import {useCommonStyles} from "../../../ui_components/common/CommonStyles.style";
import Loading from "../../../ui_components/PageLoading/Loading";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SalePriceSelectStore from "./SalePriceSelectStore";
import {Observer} from "mobx-react-lite";
import SalePriceElementRow from "./row/SalePriceElementRow";
import CancelIcon from '@mui/icons-material/Cancel';
import {Tooltip} from "@material-ui/core";
import SalePrice from "../../../entities/SalePrice";

const SalePriceSelect: React.FC<SalePriceSelectProperties> = ({
                                                                          onChange,
                                                                          disabled,
                                                                          fullWidth = false,
                                                                          className = '',
                                                                          selected
                                                                      }) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const menuRef = createRef<HTMLDivElement>();
    const inputRef = createRef<HTMLInputElement>();

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new SalePriceSelectStore(commonContext, selected);
    const containerClasses = clsx(classes.select_container, className, fullWidth && commonClasses.fullWidth);

    store.setFilter('');

    useEffect(() => {
        const clickOutside = (event: any) => {
            if (menuRef && !menuRef.current?.contains(event.target)) {
                store.toggleSearchDialog(false);
            }
        }
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuRef])

    const toggleDialog = () => {
        store.toggleSearchDialog();
        setTimeout(() => inputRef.current?.focus(), 20);
    }

    let timeout: NodeJS.Timeout;
    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        clearTimeout(timeout);
        const value = event.target.value;
        const changeFilter = () => {
            store.setFilter(value);
        };
        timeout = setTimeout(changeFilter, 800);
    }

    const selectSalePrice= (salePrice: SalePrice) => {
        store.setSelectedSalePrice(salePrice);
        onChange(salePrice);
    }

    const removeSelected = (e:React.MouseEvent) => {
        store.setSelectedSalePrice(undefined);
        e.stopPropagation();
        onChange(undefined);
    }
    return (
        <Observer>
            {() => (
                <div className={containerClasses} ref={menuRef}>
                    <div className={classes.label}>{i18n.translate("SALE_PRICE")}</div>
                    <div className={classes.input_container} onClick={toggleDialog}>
                        <RequestQuoteIcon/>
                        <div className={classes.info_container}>
                            {store.selectedSalePrice &&
                            <div className={classes.selected_container}>
                                <div className={classes.selected_text_container}>
                                    <div><b>{store.selectedSalePrice.name}</b></div>
                                    <div><b>{i18n.translate("DESCRIPTION")}:</b>{store.selectedSalePrice.description ?? '-'}</div>
                                </div>
                                <Tooltip title={i18n.translate("CLEAN")}>
                                    <div onClick={removeSelected} className={classes.remove_icon}><CancelIcon/></div>
                                </Tooltip>

                            </div>
                            }
                            {!store.selectedSalePrice && <div>{i18n.translate("SELECT_SALE_PRICE_PLACEHOLDER")}</div>}
                        </div>
                        <div className={classes.expand_icon}>
                            {store.isSearchDialogOpen && <ExpandLessIcon/>}
                            {!store.isSearchDialogOpen && <ExpandMoreIcon/>}
                        </div>
                    </div>
                    {store.isSearchDialogOpen &&
                    <div className={classes.options_container}>
                        <div className={classes.options_header}>
                            <input placeholder={i18n.translate("NAME")} ref={inputRef}
                                   onChange={onFilterChange}/>
                        </div>
                        <div className={classes.header}>
                            <div className={classes.header_option}>
                                <b>{`${i18n.translate("TOTAL")}:`} </b>{store.pagination.elementsCount}
                            </div>
                        </div>
                        <div className={classes.options_body}>
                            {store.loading && <Loading className={classes.loading} fontSize={55}/>}
                            {!store.loading && !store.isEmpty && <div className={classes.elements_container}>
                                {store.elements.map((el, i) => (
                                    <SalePriceElementRow
                                        key={i}
                                        className={className}
                                        element={el}
                                        selected={el.id === store.selectedSalePrice?.id}
                                        onSelect={selectSalePrice}
                                    />
                                ))}
                            </div>}
                            {!store.loading && store.isEmpty &&
                            <div className={classes.empty_container}>
                                <div>{i18n.translate("NO_SALE_PRICES_TITLE")}</div>
                            </div>}
                        </div>
                    </div>
                    }
                </div>
            )}
        </Observer>
    )
}


export default SalePriceSelect;