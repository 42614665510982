import Currency from "../../entities/Currency";

class MoneySalePriceValueRequest {
    public salePrice: number;
    public currency: Currency;
    public isPercentage: boolean;

    constructor(salePrice: number, currency: Currency, isPercentage: boolean) {
        this.salePrice = salePrice;
        this.currency = currency;
        this.isPercentage = isPercentage;
    }

}

export default MoneySalePriceValueRequest;