import React from 'react';
import TopupSalePriceRowProperties from './TopupSalePriceRowProperties';
import ViewSmall from '../../../../../ui_components/MediaView/ViewSmall';
import TopupSalePriceRowMedium from './TopupSalePriceRowMedium';
import ViewMedium from '../../../../../ui_components/MediaView/ViewMedium';
import ViewLarge from '../../../../../ui_components/MediaView/ViewLarge';


const TopupSalePriceRow: React.FC<TopupSalePriceRowProperties> = ({
                                                    topupProduct,
                                                    salePrice,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <TopupSalePriceRowMedium onUpdate={onUpdate} topupProduct={topupProduct} salePrice={salePrice}/>
            </ViewSmall>
            <ViewMedium>
                <TopupSalePriceRowMedium onUpdate={onUpdate} topupProduct={topupProduct} salePrice={salePrice}/>
            </ViewMedium>
            <ViewLarge>
                <TopupSalePriceRowMedium onUpdate={onUpdate} topupProduct={topupProduct} salePrice={salePrice}/>
            </ViewLarge>
        </>
    )
}

export default TopupSalePriceRow;
