import React from 'react';
import SalePriceTableHeaderProperties from './SalePriceTableHeaderProperties';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { useStyles } from './SalePriceTableHeader.style';
import SalePriceOptionsButton from '../../../../shared_components/Buttons/SalePriceOptionsButton/SalePriceOptionsButton';


const SalePriceTableHeader: React.FC<SalePriceTableHeaderProperties> = ({
                                                    onUpdate = () => {
                                                    },
                                                    salePrices
                                                  }) => {

    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.header_cell}>Servicios/Precios</TableCell>
                {salePrices.map((salePrice) => (
                <TableCell
                    key={salePrice.id}
                    align={'right'}
                    className={classes.header_cell}
                    style={{ minWidth: 180 }}
                >
                    <div className={classes.service_name_container}>
                        <div className={classes.font_bold}>{salePrice.name}</div>
                        <SalePriceOptionsButton onUpdate={onUpdate} salePriceId={salePrice.id}  salePriceName={salePrice.name}  salePriceDescription={salePrice.description}/>
                    </div>
                </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default SalePriceTableHeader;
