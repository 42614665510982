import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../../../stores/CommonStore";
import Article from "../../../../entities/Article";
import { ArticleService } from "../../../../service/articleDelivery/ArticleService";

class ArticleSalePriceStore {

    public articles: Article[];
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.articles = [];
        this.commonStore = commonStore;
        makeObservable(this, {
            articles: observable,
            isEmpty: computed
        });
    }

    get isEmpty(): boolean {
        return this.articles.length === 0;
    }

    @action
    public async getArticles() {
        const response = await ArticleService.getAll();
        if (response.success && response.data) {
            runInAction(() => {
                this.articles = response.data || [];
            })            
        }
    }

}

export default ArticleSalePriceStore;