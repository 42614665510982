import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    core_container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    tools_container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '8px',
        marginBottom: '16px'
    },
    select_container: {
        display: 'flex',
        gap: '14px',
        minWidth: '380px'
    },
    input: {
        marginTop: '16px'
    },
    tab_container_custom: {
        maxWidth: '140px',
    }
}));

export {useStyles}